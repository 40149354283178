<template>
    <div style="height: 100%">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form action="#" class="searchbox" :inline="true">
                            <el-form-item label="">
                                <el-input v-model="Keyword" type="text" size="small" placeholder="输入关键词搜索..."
                                    @keyup.enter.native="search" clearable ></el-input>
                            </el-form-item>
                            <el-form-item label="">
                                <el-select v-model="limits" placeholder="请选择合集权限" size="small"
                                    style="margin-right: 10px" @change="search">
                                    <el-option v-for="item in statusOption" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search">搜索</el-button>
                                <el-button type="primary" size="small" @click="AddBtn" plain>+发布合集</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 80px)" row-key="id" lazy
                    :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="name" width="150px" label="合集封面">
                        <template slot-scope="scope">
                            <div class="table-img">
                                <el-image style="width: 100px; height: 100px" :src="scope.row.collectionUrl"
                                    fit="contain" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="合集名称"></el-table-column>
                    <el-table-column prop="category.name" label="合集分类"></el-table-column>
                    <el-table-column width="280px" label="创建时间">
                        <template slot-scope="scope">
                            {{ scope.row.addTime | timefilters }}
                        </template>
                    </el-table-column>
                    <el-table-column width="280px" label="合集权限">
                        <template slot-scope="scope">
                            <span v-if="scope.row.limits == 0">完全公开</span>
                            <span v-if="scope.row.limits == 1">内部员工开放</span>
                            <span v-if="scope.row.limits == 2">私密发布</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="250" align="center">
                        <template slot-scope="scope">
                            <span class="success optionBtn" @click="AddVBtn(scope.row)">添加视频</span>
                            <span class="info optionBtn" @click="EditBtn(scope.row)">编辑</span>
                            <span class="primary optionBtn" @click="OpenVBtn(scope.row)">查看视频</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb";
import { Videos } from "../../../components/HospitalDomain/Videos/Videos";

export default {
    name: "OwnCollection",
    components: {
        bread
    },
    data() {
        var videos = new Videos(this.TokenClient, this.Services.Healthy)
        return {
            VideosDomain: videos,
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            tableData: [],
            Keyword: '',
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 0,
            DataTotal: 1,
            limits: '',
            statusOption: [{
                value: '',
                label: '全部'
            }, {
                value: '0',
                label: '完全公开'
            }, {
                value: '1',
                label: '内部用户开放'
            }, {
                value: '2',
                label: '私密发布'
            }],
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        search() {
            this.PageIndex = 1;
            this.getList();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        getList() {
            var _this = this;
            _this.tableData = [];
            var item = _this.searchForm;
            _this.VideosDomain.GetMyselfCollection('0', '2', '0', _this.organizationId, _this.limits, _this.Keyword, _this.PageIndex,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                },
                function (err) {
                    console.log(err);
                });
        },
        AddBtn() {
            this.$router.push({
                path: '/Videos/AddCollection',
                query: { title: '新增合集' }
            })
        },
        AddVBtn(item) {
            this.$router.push({
                path: '/Videos/AddVideos',
                query: { title: '新增合集视频', collectionId: item.id }
            })
        },
        OpenVBtn(item) {
            this.$router.push({
                path: '/Videos/CollectionVideos',
                query: { title: '合集视频', collectionId: item.id }
            })
        },
        EditBtn(item) {
            this.$router.push({
                path: '/Videos/AddCollection',
                query: {
                    id: item.id,
                    title: '编辑合集'
                }
            })
        },
        TradeBtn(item) {
            this.$router.push({
                path: '/Videos/TradeList',
                query: {
                    id: item.id,
                    totalSale: item.totalSale,
                    videoCategoryName:item.videoCategory.name
                }
            })
        },
    }
}
</script>

<style scoped>
/*content*/


.table-top {
    margin-bottom: -10px;
}

.table-img {
    position: relative;
    width: 200px;
    height: 120px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
}

.table-img img {
    width: 100%;
    height: auto;
}

.table-img p {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background-color: rgba(0, 0, 0, .5);
    font-size: 14px;
    color: #ffffff;
    text-align: center;
}

.table-info h4 {
    margin-bottom: 25px;
}

.table-info .el-row {
    margin-bottom: 15px;
}
.iq-search-bar .searchbox {
    width: auto;
    position: relative;
}
</style>
